import React from "react";
import { useUserContext } from "../context/UserContext";
import { Card } from "../components/ui/card";
import Loader from "../components/utils/Loader";
import PasswordUpdate from "../components/profile/PasswordUpdate";
import NameUpdate from "../components/profile/NameUpdate";
import EmailUpdate from "../components/profile/EmailUpdate";
import MFAManage from "../components/MFA/MFAManage";

const Profile: React.FC = () => {
  const userContext = useUserContext();

  if (!userContext) {
    return <Loader />;
  }

  const userData = userContext?.userData;
  const refetchUserData = userContext?.refetchUserData;

  return (
    <div className="max-w-4xl mx-auto p-4 space-y-6">
      <h2 className="text-3xl font-bold text-center">Profile</h2>

      {/* Name Update */}
      <NameUpdate
        initialName={userData?.userName || ""}
        refetchUserData={refetchUserData}
        authProvider={userData?.authProvider || null}
      />

      {/* Email Update */}
      <EmailUpdate
        currentEmail={userData?.userEmail || ""}
        authProvider={userData?.authProvider || null}
      />

      {/* Security Card */}
      <Card className="p-6 bg-white dark:bg-gray-800">
        <h3 className="text-xl font-bold mb-4">Security</h3>

        {/* Password Update */}
        <PasswordUpdate
          onSuccess={() => refetchUserData?.()}
          authProvider={userData?.authProvider || null}
        />

        {/* MFA Management */}
        <MFAManage
          userId={userData?.memberId || ""}
          verifiedEmail={userData?.verifiedEmail || false}
          mfaStatus={userData?.mfa_status || false}
          refetchUserData={refetchUserData}
        />
      </Card>
    </div>
  );
};

export default Profile;
