import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Input } from "../components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "../components/ui/form";
import { Label } from "../components/ui/label";
import { Button } from "../components/ui/button";
import GoogleSignIn from "../components/auth/GoogleSignIn";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Eye, EyeOff } from "lucide-react";
import GitHubOAuthButton from "../components/auth/github/GitHubOAuthButton";
import { enabledOAuthMethods } from "../config";
import MFAVerify from "../components/MFA/MFAVerify";
import { Alert } from "../components/ui/alert";
import Loader from "../components/utils/Loader";
import SEOTag from "../components/utils/SEOTag";
import { Checkbox } from "../components/ui/checkbox";

const loginSchema = z.object({
  email: z.string().email("Invalid email address."),
  password: z.string().min(6, "Password must be at least 6 characters."),
});

type LoginFormValues = z.infer<typeof loginSchema>;

const LogIn: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // MFA related states
  const [mfaRequired, setMfaRequired] = useState(false);
  const [tempToken, setTempToken] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);

  const form = useForm<LoginFormValues>({
    resolver: zodResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  // Parse query params for CLI login
  const searchParams = new URLSearchParams(location.search);
  const cli = searchParams.get("cli") === "true";
  const callbackUrl = searchParams.get("callbackUrl");

  useEffect(() => {
    // Check for MFA data in sessionStorage (e.g., from GitHub OAuth)
    const storedTempToken = sessionStorage.getItem("mfa_tempToken");
    const storedEmail = sessionStorage.getItem("mfa_email");

    if (storedTempToken && storedEmail) {
      setTempToken(storedTempToken);
      setUserEmail(storedEmail);
      setMfaRequired(true);

      // Clear sessionStorage to prevent re-triggering
      sessionStorage.removeItem("mfa_tempToken");
      sessionStorage.removeItem("mfa_email");
    }
  }, []);

  const onSubmit = async (data: LoginFormValues) => {
    setError(null);

    try {
      setLoading(true);
      const response = await fetch(`/api/auth/login${cli ? "?cli=true" : ""}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();

        if (errorData.redirect) {
          navigate(errorData.redirect); // Redirect to sign-up if user not found
          return;
        }

        throw new Error(errorData.error || "Login failed.");
      }

      const responseData = await response.json();

      if (responseData.mfaRequired && responseData.tempToken) {
        // MFA is required, store tempToken and show MFA input
        setTempToken(responseData.tempToken);
        setUserEmail(data.email);
        setMfaRequired(true);
      } else {
        // No MFA required, login successful
        // Token is already set in the cookie by the API
        // Redirect based on CLI mode
        if (
          cli &&
          callbackUrl &&
          responseData.userName &&
          responseData.userEmail
        ) {
          const url = new URL(callbackUrl);
          url.searchParams.set("userName", responseData.userName);
          url.searchParams.set("userEmail", responseData.userEmail);
          window.location.href = url.toString();
        } else {
          navigate("/dashboard");
        }
      }
    } catch (err: any) {
      console.error("Error during login:", err.message);
      setError(err.message || "An unknown error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse: any) => {
    setError(null);

    try {
      setLoading(true);
      const { credential } = credentialResponse;

      if (!credential) {
        throw new Error("Missing credential in Google response.");
      }

      const response = await fetch(
        `/api/auth/google-signin${cli ? "?cli=true" : ""}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: credential }), // Ensure correct field name
          credentials: "include",
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        if (errorData.redirect) {
          navigate(errorData.redirect);
          return;
        }
        throw new Error(errorData.error || "Google login failed.");
      }

      const responseData = await response.json();

      if (responseData.mfaRequired && responseData.tempToken) {
        // MFA is required
        setTempToken(responseData.tempToken);
        setUserEmail(responseData.email);
        setMfaRequired(true);
      } else {
        // No MFA required, login successful
        // Token is already set in the cookie by the API
        // Redirect based on CLI mode
        if (
          cli &&
          callbackUrl &&
          responseData.userName &&
          responseData.userEmail
        ) {
          const url = new URL(callbackUrl);
          url.searchParams.set("userName", responseData.userName);
          url.searchParams.set("userEmail", responseData.userEmail);
          window.location.href = url.toString();
        } else {
          navigate("/dashboard");
        }
      }
    } catch (err: any) {
      console.error("Google Sign-In Error:", err.message);
      setError(err.message || "Google sign-in failed.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLoginError = () => {
    console.error("Google login failed. Please try again.");
    setError("Google login failed. Please try again.");
  };

  const handleMFASuccess = async () => {
    // After successful MFA verification, user is logged in
    navigate("/dashboard");
  };

  if (loading) {
    return <Loader />;
  }

  const hasOAuthMethods = enabledOAuthMethods.some(
    (method) => method !== "email"
  );

  return (
    <>
      <SEOTag
        title="Login - My SaaS App"
        description="Log in to your account"
        url="https://www.create-saas-app.com"
        image="https://mywebsite.com/about-og-image.jpg"
      />

      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
      >
        <div className="flex justify-center items-center min-h-screen bg-gray-50">
          <div className="max-w-md w-full shadow-md p-6 bg-white">
            <h1 className="text-2xl font-bold text-center mb-4">Log In</h1>

            {error && (
              <Alert>
                <p>{error}</p>
              </Alert>
            )}

            {/* If MFA is required, show MFA verification component */}
            {mfaRequired && tempToken && userEmail ? (
              <MFAVerify
                tempToken={tempToken}
                email={userEmail}
                onSuccess={handleMFASuccess}
              />
            ) : (
              <>
                {/* Email Login */}
                {enabledOAuthMethods.includes("email") && (
                  <Form {...form}>
                    <form
                      onSubmit={form.handleSubmit(onSubmit)}
                      className="space-y-4"
                    >
                      <FormField
                        name="email"
                        render={({ field }) => (
                          <FormItem>
                            <Label htmlFor="email">Email</Label>
                            <FormControl>
                              <Input
                                id="email"
                                type="email"
                                placeholder="Enter your email"
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        name="password"
                        render={({ field }) => (
                          <FormItem>
                            <Label htmlFor="password">Password</Label>
                            <FormControl>
                              <div className="relative">
                                <Input
                                  id="password"
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Enter your password"
                                  {...field}
                                />
                                <button
                                  type="button"
                                  onClick={() => setShowPassword(!showPassword)}
                                  className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                                >
                                  {showPassword ? (
                                    <EyeOff className="w-5 h-5" />
                                  ) : (
                                    <Eye className="w-5 h-5" />
                                  )}
                                </button>
                              </div>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      {/* <div className="flex items-center space-x-2">
                       <Checkbox
                         id="rememberMe"
                         checked={false} // Implement remember me if needed
                         onCheckedChange={() => {}} // Implement remember me if needed
                       />
                       <label
                         htmlFor="rememberMe"
                         className="text-sm text-gray-600"
                       >
                         Remember me
                       </label>
                     </div> */}
                      <Button type="submit" className="w-full">
                        Log In
                      </Button>
                    </form>
                  </Form>
                )}

                {/* OAuth Options */}
                {hasOAuthMethods && (
                  <div className="mt-6">
                    <p className="text-center">Or log in with:</p>
                    <div className="flex flex-col justify-center items-center mt-2 h-full space-y-4">
                      {enabledOAuthMethods.includes("google") && (
                        <GoogleSignIn
                          onSuccess={handleGoogleLoginSuccess}
                          onFailure={handleGoogleLoginError}
                        />
                      )}
                      {enabledOAuthMethods.includes("github") && (
                        <GitHubOAuthButton
                          action="signin"
                          label="Log in with GitHub"
                        />
                      )}
                    </div>
                  </div>
                )}

                {/* Signup Link */}
                <div className="mt-6 text-center">
                  <p>
                    Don't have an account?{" "}
                    <Button
                      variant="link"
                      onClick={() => navigate("/signup")}
                      className="p-0"
                    >
                      Sign Up
                    </Button>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </GoogleOAuthProvider>
    </>
  );
};

export default LogIn;
