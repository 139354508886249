import React from "react";
import { GoogleLogin } from "@react-oauth/google";

interface GoogleSignInProps {
  onSuccess: (credentialResponse: any) => void;
  onFailure: () => void;
}

const GoogleSignIn: React.FC<GoogleSignInProps> = ({
  onSuccess,
  onFailure,
}) => {
  return (
    <div className="google-signin-container">
      <GoogleLogin onSuccess={onSuccess} onError={onFailure} />
    </div>
  );
};

export default GoogleSignIn;
