import React from "react";
import { Card } from "../components/ui/card";
import { Button } from "../components/ui/button";
import { User, Activity, Bell } from "lucide-react";
import {
  Sheet,
  SheetTrigger,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetDescription,
} from "../components/ui/sheet";
import { Bar, BarChart, CartesianGrid, XAxis, Tooltip } from "recharts";
import { ChartContainer, ChartTooltipContent } from "../components/ui/chart";
import { useUserContext } from "../context/UserContext";
import Loader from "../components/utils/Loader";

const WelcomeUser: React.FC = () => {
  const userContext = useUserContext();
  if (!userContext) {
    return <Loader />;
  }
  const { userData } = userContext;

  const userName = userData?.userName || "Guest";

  const chartData = [
    { month: "January", desktop: 186, mobile: 80 },
    { month: "February", desktop: 305, mobile: 200 },
    { month: "March", desktop: 237, mobile: 120 },
    { month: "April", desktop: 73, mobile: 190 },
    { month: "May", desktop: 209, mobile: 130 },
    { month: "June", desktop: 214, mobile: 140 },
  ];

  const chartConfig = {
    desktop: { label: "Desktop", color: "hsl(var(--chart-1))" },
    mobile: { label: "Mobile", color: "hsl(var(--chart-2))" },
  };

  return (
    <div className="space-y-6 w-full px-4">
      {/* Welcome Section */}
      <div className="flex flex-col sm:flex-row justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold text-gray-800 dark:text-gray-100">
            Welcome back, {userName}!
          </h1>
          <p className="text-gray-600 dark:text-gray-300">
            Here's an overview of your account.
          </p>
        </div>

        {/* Notifications Button */}
        <Sheet>
          <SheetTrigger asChild>
            <Button variant="outline">
              <Bell className="w-4 h-4 mr-2" />
              Notifications
            </Button>
          </SheetTrigger>
          <SheetContent side="right" className="w-full sm:w-[400px]">
            <SheetHeader>
              <SheetTitle>Notifications</SheetTitle>
              <SheetDescription>
                Here's a summary of your recent notifications.
              </SheetDescription>
            </SheetHeader>
            <div className="p-4 space-y-4">
              <Card className="p-4">
                <h3 className="text-lg font-semibold">New User Signup</h3>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  John Doe signed up an hour ago.
                </p>
              </Card>
              <Card className="p-4">
                <h3 className="text-lg font-semibold">Payment Received</h3>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  You've received a payment of $150.
                </p>
              </Card>
              <Card className="p-4">
                <h3 className="text-lg font-semibold">System Alert</h3>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  Scheduled maintenance on Friday at 12:00 AM.
                </p>
              </Card>
            </div>
          </SheetContent>
        </Sheet>
      </div>

      {/* Cards and Chart Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        <Card className="p-6 bg-white dark:bg-gray-800 shadow-md">
          <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
            Users
          </h2>
          <p className="text-gray-600 dark:text-gray-300">
            Total users: <span className="font-bold">1,234</span>
          </p>
          <Button variant="default" className="mt-4">
            <User className="w-4 h-4 mr-2" />
            Manage Users
          </Button>
        </Card>

        <Card className="p-6 bg-white dark:bg-gray-800 shadow-md">
          <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
            Activities
          </h2>
          <p className="text-gray-600 dark:text-gray-300">
            Last 24 hours: <span className="font-bold">789</span> activities
          </p>
          <Button variant="default" className="mt-4">
            <Activity className="w-4 h-4 mr-2" />
            View Activities
          </Button>
        </Card>

        <Card className="p-6 bg-white dark:bg-gray-800 shadow-md">
          <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
            Notifications
          </h2>
          <p className="text-gray-600 dark:text-gray-300">
            Unread: <span className="font-bold">12</span>
          </p>
          <Button variant="default" className="mt-4">
            <Bell className="w-4 h-4 mr-2" />
            Check Notifications
          </Button>
        </Card>

        <Card className="p-6 bg-white dark:bg-gray-800 shadow-md col-span-1 lg:col-span-2">
          <h2 className="text-lg font-semibold text-gray-800 dark:text-gray-100 mb-2">
            User Statistics
          </h2>
          <ChartContainer config={chartConfig} className="min-h-[150px] w-full">
            <BarChart data={chartData}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="month"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) => value.slice(0, 3)}
              />
              <Tooltip content={<ChartTooltipContent />} />
              <Bar dataKey="desktop" fill="var(--color-desktop)" radius={4} />
              <Bar dataKey="mobile" fill="var(--color-mobile)" radius={4} />
            </BarChart>
          </ChartContainer>
        </Card>
      </div>
    </div>
  );
};

export default WelcomeUser;
