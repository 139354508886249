import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PricingPlan from "../components/utils/PricingPlan";
import pricingPlans from "../data/PricingPlans";
import { Button } from "../components/ui/button";
import { useUserContext } from "../context/UserContext";

const PricingPage: React.FC = () => {
  const [activeTab, setActiveTab] = useState<
    "monthly" | "quarterly" | "one-time"
  >("monthly");
  const [currentPlans, setCurrentPlans] = useState(pricingPlans["monthly"]);
  const navigate = useNavigate();
  const userContext = useUserContext();

  const userPlanName = userContext?.userData?.planName || null;

  const isAuthenticated = async (): Promise<boolean> => {
    try {
      const response = await fetch("/api/check-auth", {
        method: "GET",
        credentials: "include",
      });

      if (response.status === 200) {
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error checking authentication:", error);
      return false;
    }
  };

  const handleTabChange = (tab: "monthly" | "quarterly" | "one-time") => {
    setActiveTab(tab);
    setCurrentPlans(pricingPlans[tab]);
  };

  const handlePlanSelection = async (plan: {
    id: string;
    trialDays: number;
    type: "subscription" | "one-time";
  }) => {
    const { id: priceId, trialDays, type } = plan;

    try {
      const authenticated = await isAuthenticated();

      const mode = type === "subscription" ? "subscription" : "payment";

      // Proceed to create checkout session
      const response = await fetch("/api/stripe/create-checkout-session", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ priceId, trialDays, mode }),
        credentials: "include",
      });

      console.log(plan);

      if (!response.ok) {
        throw new Error("Failed to create checkout session");
      }

      const data = await response.json();
      window.location.href = data.url; // Redirect to Stripe Checkout
    } catch (error) {
      console.error("Error handling plan selection:", error);
    }
  };

  return (
    <div className="pricing-page">
      {/* Tabs */}
      <div className="tabs mx-auto max-w-[1400px] flex justify-center mb-8 gap-4">
        <Button
          variant={activeTab === "monthly" ? "default" : "outline"}
          onClick={() => handleTabChange("monthly")}
        >
          Monthly
        </Button>
        <Button
          variant={activeTab === "quarterly" ? "default" : "outline"}
          onClick={() => handleTabChange("quarterly")}
        >
          Quarterly
        </Button>
        <Button
          variant={activeTab === "one-time" ? "default" : "outline"}
          onClick={() => handleTabChange("one-time")}
        >
          One-Time
        </Button>
      </div>

      {/* Plans */}
      <div className="plans flex flex-wrap justify-center gap-6 max-w-[1400px] mx-auto">
        {currentPlans.map((plan) => (
          <PricingPlan
            key={`${activeTab}-${plan.id}`}
            name={plan.name}
            price={plan.price}
            features={plan.features}
            onSelectPlan={
              userPlanName === plan.name
                ? undefined
                : () => handlePlanSelection(plan)
            }
            currentPlan={userPlanName === plan.name}
          />
        ))}
      </div>
    </div>
  );
};

export default PricingPage;
