import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import SignUp from "./pages/SignUp";
import PricingPage from "./pages/Pricing";
import LogIn from "./pages/Login";
import PrivateRoute from "./routes/PrivateRoute";
import PaywallRoute from "./routes/PaywallRoute";
import "./index.css";
import RequestPasswordReset from "./pages/password/RequestPasswordReset";
import ConfirmPasswordReset from "./pages/password/ConfirmPasswordReset";
import Profile from "./pages/Profile";
import WelcomeUser from "./pages/WelcomeUser";
import VerifyEmail from "./pages/VerifyEmail";
import { UserProvider } from "./context/UserContext";
import { Toaster } from "./components/ui/sonner";
import Plans from "./pages/Plans";
import GitHubCallback from "./components/auth/github/callback";
// import { initGA, trackPageView } from "./lib/analytics";
import { useEffect } from "react";
import AfterPurchase from "./pages/AfterPurchase";

const App = () => {
  // useEffect(() => {
  //   initGA();
  // }, []);
  return (
    <>
      <Router>
        <Toaster />
        {/* <GAListener> */}
        <Routes>
          {/* Public routes */}
          <Route path="/signup" element={<SignUp />} />
          <Route path="/login" element={<LogIn />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route
            path="/password-reset-request"
            element={<RequestPasswordReset />}
          />
          <Route path="/reset-password" element={<ConfirmPasswordReset />} />
          <Route path="/verify-email" element={<VerifyEmail />} />

          {/* GitHub OAuth Page */}
          <Route path="/auth/github/callback" element={<GitHubCallback />} />

          <Route
            path="/redirecting-to-dashboard"
            element={
              <PrivateRoute>
                <AfterPurchase />
              </PrivateRoute>
            }
          />

          {/* Private routes */}
          <Route
            path="/dashboard"
            element={
              <UserProvider>
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              </UserProvider>
            }
          >
            {/* Nested routes under /dashboard */}
            <Route path="profile" element={<Profile />} />
            <Route path="plans" element={<Plans></Plans>} />

            {/* Default dashboard page */}
            <Route index element={<WelcomeUser />} />
          </Route>
        </Routes>
        {/* </GAListener> */}
      </Router>
    </>
  );
};

// const GAListener: React.FC<React.PropsWithChildren> = ({ children }) => {
//   const location = useLocation();
//   useEffect(() => {
//     trackPageView(location.pathname); // Track pageview
//   }, [location]);

//   return <>{children}</>;
// };

export default App;
