import React from "react";
import { Card, CardContent, CardHeader } from "../ui/card";
import { Button } from "../ui/button";

interface PricingPlanProps {
  name: string;
  price: string;
  features: string[];
  onSelectPlan?: () => void; // Made optional
  currentPlan?: boolean;
}

const PricingPlan: React.FC<PricingPlanProps> = ({
  name,
  price,
  features,
  onSelectPlan,
  currentPlan = false,
}) => {
  return (
    <Card className="p-4">
      <CardHeader>
        <h2 className="text-lg font-bold">{name}</h2>
        <p className="text-gray-600">{price}</p>
      </CardHeader>
      <CardContent>
        <ul className="mb-4">
          {features.map((feature, index) => (
            <li key={index} className="text-sm text-gray-800">
              {feature}
            </li>
          ))}
        </ul>
        {currentPlan ? (
          <div className="text-green-600 font-semibold">Current Plan</div>
        ) : (
          <Button variant="default" onClick={onSelectPlan}>
            Select Plan
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default PricingPlan;
