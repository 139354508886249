import React, { useState, useEffect } from "react";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Card } from "../../components/ui/card";
import { Edit, Save, X } from "lucide-react";
import { toast } from "sonner";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../components/ui/tooltip";

interface EmailUpdateProps {
  currentEmail: string;
  authProvider: string | null;
  refetchUserData?: () => Promise<void>;
}

const EmailUpdate: React.FC<EmailUpdateProps> = ({
  currentEmail,
  authProvider,
  refetchUserData,
}) => {
  const [email, setEmail] = useState(currentEmail);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEmail(currentEmail);
  }, [currentEmail]);

  const updateEmail = async () => {
    try {
      setLoading(true);

      const response = await fetch("/api/profile/change-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to update email.");
      }

      toast.success(
        "Email updated successfully! Please check your inbox for a confirmation link."
      );

      setIsEditing(false);
      if (refetchUserData) await refetchUserData();
    } catch (err: any) {
      toast.error(err.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      className={`p-6 ${
        authProvider
          ? "bg-gray-200 dark:bg-gray-800"
          : "bg-white dark:bg-gray-900"
      }`}
    >
      <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="flex-1">
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700 dark:text-gray-300"
          >
            Email
          </label>
          {authProvider ? (
            <p className="mt-1 text-lg text-gray-800 dark:text-gray-100">
              {currentEmail}
            </p>
          ) : isEditing ? (
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="mt-1"
              autoFocus
            />
          ) : (
            <p className="mt-1 text-lg text-gray-800 dark:text-gray-100">
              {currentEmail}
            </p>
          )}
        </div>
        <div className="flex-shrink-0">
          {authProvider ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="cursor-not-allowed">
                  <Button variant="outline" size="sm" disabled>
                    <Edit className="w-4 h-4 mr-1" />
                    Unavailable
                  </Button>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                Changes are unavailable because this is an OAuth account.
              </TooltipContent>
            </Tooltip>
          ) : isEditing ? (
            <div className="flex space-x-2">
              <Button
                variant="secondary"
                size="sm"
                onClick={updateEmail}
                disabled={loading}
              >
                <Save className="w-4 h-4 mr-1" />
                Save
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  setIsEditing(false);
                  setEmail(currentEmail);
                }}
              >
                <X className="w-4 h-4 mr-1" />
                Cancel
              </Button>
            </div>
          ) : (
            <Button
              variant="outline"
              size="sm"
              onClick={() => setIsEditing(true)}
            >
              <Edit className="w-4 h-4 mr-1" />
              Change
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

export default EmailUpdate;
