import React, { useState } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Card } from "../ui/card";
import MFASetup from "../MFA/MFASetup";
import { toast } from "sonner";

interface MFAManageProps {
  userId: string;
  verifiedEmail: boolean;
  mfaStatus: boolean;
  refetchUserData: () => Promise<void>;
}

const MFAManage: React.FC<MFAManageProps> = ({
  userId,
  verifiedEmail,
  mfaStatus,
  refetchUserData,
}) => {
  const [mfaSetupData, setMfaSetupData] = useState<{
    qrCode: string;
    tempSecret: string;
    userId: string;
    email: string;
  } | null>(null);
  const [loading, setLoading] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const handleEnableMFA = async () => {
    setLoading(true);
    try {
      const response = await fetch("/api/MFA/mfa-generate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to generate MFA setup.");
      }

      const data = await response.json();
      setMfaSetupData(data);
    } catch (error: any) {
      toast.error(error.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleDisableMFA = async () => {
    if (!verifiedEmail) {
      toast.error("You must verify your email before disabling MFA.");
      return;
    }

    try {
      const response = await fetch("/api/MFA/mfa-disable", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to disable MFA.");
      }

      toast.success("MFA has been disabled.");
      refetchUserData?.();
    } catch (error: any) {
      toast.error(error.message || "An error occurred.");
    } finally {
      setIsConfirmOpen(false);
    }
  };

  return (
    <div className="flex flex-col sm:flex-row items-center justify-between gap-4 my-4">
      <div className="flex-1">
        <h2 className="text-lg font-semibold">Multi-Factor Authentication</h2>
        <p className="text-sm text-gray-600 dark:text-gray-400">
          {mfaStatus
            ? "MFA is currently enabled for your account."
            : "MFA is currently disabled for your account."}
        </p>
      </div>
      <div className="flex-shrink-0">
        {mfaStatus ? (
          <>
            <Button variant="outline" onClick={() => setIsConfirmOpen(true)}>
              Disable MFA
            </Button>
            <Dialog open={isConfirmOpen} onOpenChange={setIsConfirmOpen}>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Disable MFA</DialogTitle>
                  <DialogDescription>
                    Are you sure you want to disable Multi-Factor
                    Authentication? This will reduce your account's security.
                  </DialogDescription>
                </DialogHeader>
                <div className="flex justify-end space-x-4 mt-4">
                  <Button
                    variant="ghost"
                    onClick={() => setIsConfirmOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="destructive" onClick={handleDisableMFA}>
                    Disable MFA
                  </Button>
                </div>
              </DialogContent>
            </Dialog>
          </>
        ) : (
          <Dialog>
            <DialogTrigger asChild>
              <Button
                variant="default"
                onClick={handleEnableMFA}
                disabled={loading}
                size={"sm"}
              >
                {loading ? "Loading..." : "Enable MFA"}
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Set Up MFA</DialogTitle>
                <DialogDescription>
                  Protect your account by setting up multi-factor
                  authentication.
                </DialogDescription>
              </DialogHeader>
              {mfaSetupData && (
                <MFASetup
                  userId={mfaSetupData.userId}
                  email={mfaSetupData.email}
                  qrCode={mfaSetupData.qrCode}
                  tempSecret={mfaSetupData.tempSecret}
                  onSuccess={() => {
                    toast.success("MFA setup complete!");
                    refetchUserData?.();
                  }}
                />
              )}
            </DialogContent>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default MFAManage;
