import React, { useState } from "react";
import { Alert } from "../../components/ui/alert";
import { Label } from "../../components/ui/label";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";

const PasswordResetRequest: React.FC = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage(null);
    setError(null);

    try {
      const response = await fetch("/api/password/request-password-reset", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to request password reset.");
      }

      setMessage("A password reset link has been sent.");
    } catch (err: any) {
      setError(err.message || "An error occurred. Please try again.");
    }
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-50">
      <div className="w-full max-w-md p-6 shadow-md rounded-md bg-white">
        <h1 className="text-2xl font-bold text-center mb-4">Reset Password</h1>
        {message && (
          <Alert variant={"default"}>
            <p>{message}</p>
          </Alert>
        )}
        {error && (
          <Alert variant="destructive">
            <p>{error}</p>
          </Alert>
        )}
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <Label htmlFor="email">Enter your email</Label>
            <Input
              type="email"
              id="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <Button type="submit" className="w-full">
            Request Reset Link
          </Button>
        </form>
      </div>
    </div>
  );
};

export default PasswordResetRequest;
