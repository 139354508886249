import React, { useState, useEffect } from "react";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { Card } from "../../components/ui/card";
import { Edit, Save, X } from "lucide-react";
import { toast } from "sonner";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../../components/ui/tooltip";

interface NameUpdateProps {
  initialName: string;
  authProvider: string | null;
  refetchUserData?: () => Promise<void>;
}

const NameUpdate: React.FC<NameUpdateProps> = ({
  initialName,
  authProvider,
  refetchUserData,
}) => {
  const [name, setName] = useState(initialName);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  const updateName = async () => {
    try {
      setLoading(true);

      const response = await fetch("/api/profile/change-name", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to update name.");
      }

      toast.success("Name updated successfully!");
      setIsEditing(false);
      if (refetchUserData) await refetchUserData();
    } catch (err: any) {
      toast.error(err.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      className={`p-6 ${
        authProvider
          ? "bg-gray-200 dark:bg-gray-800"
          : "bg-white dark:bg-gray-900"
      }`}
    >
      <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="flex-1">
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700 dark:text-gray-300"
          >
            Name
          </label>
          {authProvider ? (
            <p className="mt-1 text-lg text-gray-800 dark:text-gray-100">
              {initialName}
            </p>
          ) : isEditing ? (
            <Input
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1"
              autoFocus
            />
          ) : (
            <p className="mt-1 text-lg text-gray-800 dark:text-gray-100">
              {initialName}
            </p>
          )}
        </div>
        <div className="flex-shrink-0">
          {authProvider ? (
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="cursor-not-allowed">
                  <Button variant="outline" size="sm" disabled>
                    <Edit className="w-4 h-4 mr-1" />
                    Unavailable
                  </Button>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                Changes are unavailable because this is an OAuth account.
              </TooltipContent>
            </Tooltip>
          ) : isEditing ? (
            <div className="flex space-x-2">
              <Button
                variant="secondary"
                size="sm"
                onClick={updateName}
                disabled={loading}
              >
                <Save className="w-4 h-4 mr-1" />
                Save
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setIsEditing(false)}
              >
                <X className="w-4 h-4 mr-1" />
                Cancel
              </Button>
            </div>
          ) : (
            <Button
              variant="outline"
              size="sm"
              onClick={() => setIsEditing(true)}
            >
              <Edit className="w-4 h-4 mr-1" />
              Change
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

export default NameUpdate;
