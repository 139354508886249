import React, { useState } from "react";
import { Input } from "./../ui/input";
import { Button } from "./../ui/button";
import { Alert } from "./../ui/alert";
import { Label } from "./../ui/label";

type MFASetupProps = {
  userId: string;
  email: string;
  qrCode: string;
  tempSecret: string;
  onSuccess: () => void;
};

const MFASetup: React.FC<MFASetupProps> = ({
  userId,
  email,
  qrCode,
  tempSecret,
  onSuccess,
}) => {
  const [totpCode, setTotpCode] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [skipping, setSkipping] = useState(false);

  const handleVerify = async () => {
    setError(null);
    setLoading(true);
    try {
      const response = await fetch("/api/MFA/mfa-setup", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          verifyMFA: true,
          tempSecret,
          totpCode,
          userId,
          email,
        }),
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "MFA verification failed.");
      }

      // If success
      onSuccess();
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSkip = async () => {
    setError(null);
    setSkipping(true);
    try {
      const response = await fetch("/api/MFA/mfa-skip", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          userId,
          email,
        }),
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Skipping MFA Failed");
      }
      onSuccess();
    } catch (err: any) {
      setError(err.message);
    } finally {
      setSkipping(false);
    }
  };

  return (
    <div className="p-4 bg-white border rounded shadow-md space-y-4">
      <h2 className="text-xl font-bold text-center">Set Up MFA</h2>
      {error && (
        <Alert>
          <p>{error}</p>
        </Alert>
      )}
      <p className="text-sm text-gray-600">
        Scan this QR code with your Google Authenticator app, then enter the
        6-digit code below:
      </p>
      <div className="flex justify-center">
        <img src={qrCode} alt="MFA QR Code" className="border p-2" />
      </div>
      <div className="space-y-2">
        <div>
          <Label htmlFor="totpCode">TOTP Code</Label>
          <Input
            id="totpCode"
            type="text"
            placeholder="123456"
            value={totpCode}
            onChange={(e) => setTotpCode(e.target.value)}
          />
        </div>
        <Button onClick={handleVerify} className="w-full" disabled={loading}>
          {loading ? "Verifying..." : "Verify"}
        </Button>
        {/* Display "Skip MFA" button only if MFA is optional */}
        <Button
          onClick={handleSkip}
          className="w-full mt-2 bg-gray-500 hover:bg-gray-600"
          disabled={skipping}
          variant="outline"
        >
          {skipping ? "Skipping..." : "Skip MFA"}
        </Button>
      </div>
    </div>
  );
};

export default MFASetup;
