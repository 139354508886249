import React from "react";
import { Navigate } from "react-router-dom";
import Loader from "../components/utils/Loader";

const PrivateRoute: React.FC<{ children: React.ReactElement }> = ({
  children,
}) => {
  const [authenticated, setAuthenticated] = React.useState<boolean | null>(
    null
  );

  React.useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch("/api/check-auth", {
          method: "GET",
          credentials: "include",
        });

        setAuthenticated(response.ok);
      } catch {
        setAuthenticated(false);
      }
    };

    checkAuth();
  }, []);

  if (authenticated === null) {
    return <Loader></Loader>;
  }

  return authenticated ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;
