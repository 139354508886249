type Plan = {
  id: string;
  name: string;
  price: string;
  features: string[];
  trialDays: number;
  type: "subscription" | "one-time";
};

type PricingPlans = {
  [key: string]: Plan[];
};

const pricingPlans: PricingPlans = {
  monthly: [
    {
      id: "price_1QSLdAK9RBFbH3W8jyEMokBo",
      name: "Basic",
      price: "$500",
      features: ["Feature 1", "Feature 2"],
      trialDays: 1,
      type: "subscription",
    },
    {
      id: "price_1QSLe8K9RBFbH3W8HySwm8Yv",
      name: "One-Time",
      price: "$20",
      features: ["Feature 1", "Feature 2", "Feature 3"],
      trialDays: 14,
      type: "one-time",
    },
    {
      id: "price_1QSLfBK9RBFbH3W8tb5gUpbe",
      name: "No-Trial",
      price: "$30",
      features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4"],
      trialDays: 0,
      type: "subscription",
    },
  ],

  //-------------------------------
  //Copy this strucure for as many billing periods as you want. The data will be auto imported to the Pricing Page
  //-------------------------------

  quarterly: [
    {
      id: "your-plan-id-1",
      name: "Basic",
      price: "$25",
      features: ["Feature 1", "Feature 2"],
      trialDays: 7,
      type: "subscription",
    },
    {
      id: "your-plan-id-2",
      name: "Professional",
      price: "$50",
      features: ["Feature 1", "Feature 2", "Feature 3"],
      trialDays: 14,
      type: "subscription",
    },
    {
      id: "your-plan-id-3",
      name: "Elite",
      price: "$75",
      features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4"],
      trialDays: 0,
      type: "subscription",
    },
  ],
  "one-time": [],
};

export default pricingPlans;
export type { Plan, PricingPlans };
