import React, { useEffect, useState } from "react";
import {
  SidebarProvider,
  Sidebar,
  SidebarTrigger,
  SidebarRail,
  SidebarInset,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarGroupContent,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
} from "../components/ui/sidebar";
import {
  Home,
  User,
  Settings,
  LogOut,
  Lock,
  Moon,
  Sun,
  CreditCard,
} from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
} from "../components/ui/dropdown-menu";
import { useUserContext } from "../context/UserContext";
import Loader from "../components/utils/Loader";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "../components/ui/tooltip";
import NotificationBar from "../components/utils/NotificationBar";

interface DashboardLayoutProps {
  children: React.ReactNode;
  verifiedEmail: boolean | null;
}

const menuItems = [
  {
    title: "Dashboard",
    href: "/dashboard",
    icon: Home,
  },
  {
    title: "Profile",
    href: "/dashboard/profile",
    icon: User,
  },
  {
    title: "Metrics",
    href: "/dashboard/metrics",
    icon: Settings,
  },
  {
    title: "Plans",
    href: "/dashboard/plans",
    icon: CreditCard,
  },
];

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
  verifiedEmail,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const userContext = useUserContext();

  const [theme, setTheme] = useState<"light" | "dark">(() => {
    // Get initial theme from localStorage or default to "light"
    return (localStorage.getItem("theme") as "light" | "dark") || "light";
  });

  // Apply the theme class to the body
  useEffect(() => {
    document.body.classList.toggle("dark", theme === "dark");
    document.body.classList.toggle("light", theme === "light");
    localStorage.setItem("theme", theme);
  }, [theme]);

  if (!userContext) {
    return <Loader />;
  }

  const { userData, refetchUserData } = userContext;

  const userName = userData?.userName || "Guest";
  const userEmail = userData?.userEmail || "guest@example.com";
  const planName = userData?.planName || null;
  const stripeCustomerId = userData?.stripeCustomerId || null;
  const trialEndsIn = userData?.trialEndsIn || null;

  const handleLogout = async () => {
    try {
      await fetch("/api/auth/logout", {
        method: "POST",
        credentials: "include",
      });
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleBilling = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        "/api/stripe/create-customer-portal-session",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ stripeCustomerId }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to create billing session.");
      }

      const { url } = await response.json();
      window.open(url, "_blank");
    } catch (error: any) {
      console.error("Error managing billing:", error.message);
      setError(error.message || "An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <SidebarProvider>
      <div className="flex w-full">
        {/* Sidebar */}
        <Sidebar>
          <SidebarHeader>
            <h2 className="text-xl font-bold text-gray-800 dark:text-gray-100">
              Your_SaaS
            </h2>
          </SidebarHeader>
          <SidebarContent>
            <SidebarGroup>
              <SidebarGroupLabel className="text-gray-700 dark:text-gray-300">
                Main Menu
              </SidebarGroupLabel>
              <SidebarGroupContent>
                <SidebarMenu>
                  {menuItems.map((item) => (
                    <SidebarMenuItem key={item.title}>
                      <SidebarMenuButton asChild>
                        <Link to={item.href}>
                          <item.icon className="w-4 h-4 mr-2 text-gray-700 dark:text-gray-300" />
                          <span className="text-gray-800 dark:text-gray-100">
                            {item.title}
                          </span>
                        </Link>
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  ))}
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
          </SidebarContent>
          <SidebarFooter>
            {/* User Info Dropdown */}
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <div className="flex items-center justify-between cursor-pointer p-2">
                  <div>
                    <p className="font-semibold text-gray-800 dark:text-gray-100">
                      {userName}
                    </p>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      {userEmail}
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      {planName ? `Plan: ${planName}` : "Free Plan"}
                    </p>
                    {trialEndsIn && (
                      <p className="text-xs text-gray-500 dark:text-gray-400">
                        Trial ends in {trialEndsIn} days
                      </p>
                    )}
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-gray-600 dark:text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-48 mt-2 bg-white dark:bg-gray-800 rounded-md shadow-lg">
                <DropdownMenuLabel className="text-gray-800 dark:text-gray-100">
                  User Options
                </DropdownMenuLabel>
                <DropdownMenuItem onClick={() => navigate("plans")}>
                  Upgrade Plan
                </DropdownMenuItem>
                <DropdownMenuItem asChild>
                  {stripeCustomerId ? (
                    <button
                      className="w-full text-left p-2 flex items-center justify-start text-gray-800 dark:text-gray-100"
                      onClick={handleBilling}
                    >
                      Billing
                    </button>
                  ) : (
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="w-full text-left p-2 flex items-center justify-start cursor-not-allowed text-gray-600 dark:text-gray-400">
                          Billing
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        Choose a paid plan to access billing.
                      </TooltipContent>
                    </Tooltip>
                  )}
                </DropdownMenuItem>
                <DropdownMenuItem
                  onClick={handleLogout}
                  className="text-gray-800 dark:text-gray-100"
                >
                  Log Out
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>

            {/* Theme Toggle */}
            <div className="flex items-center justify-between p-2 mt-2">
              <span className="text-sm font-medium text-gray-800 dark:text-gray-100">
                {theme === "dark" ? "Dark Mode" : "Light Mode"}
              </span>
              <button
                className="p-2 rounded-full bg-gray-200 dark:bg-gray-700"
                onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
                aria-label="Toggle theme"
              >
                {theme === "dark" ? (
                  <Sun className="w-4 h-4 text-gray-800 dark:text-gray-100" />
                ) : (
                  <Moon className="w-4 h-4 text-gray-800 dark:text-gray-100" />
                )}
              </button>
            </div>
          </SidebarFooter>
        </Sidebar>

        {/* Sidebar Rail */}
        <SidebarRail />

        {/* Main Content */}
        <SidebarInset className="flex-1 flex flex-col w-full">
          <header>
            <NotificationBar verifiedEmail={verifiedEmail}></NotificationBar>
            <SidebarTrigger />
          </header>
          <main className="flex-1 p-4 overflow-auto w-full">{children}</main>
        </SidebarInset>
      </div>

      {/* Error Message */}
      {error && <div className="text-red-500 mt-2 text-center">{error}</div>}
      {loading && <Loader />}
    </SidebarProvider>
  );
};

export default DashboardLayout;
