import React from "react";
import { Alert, AlertTitle, AlertDescription } from "../ui/alert";
import { Button } from "../ui/button";
import { toast } from "sonner";

interface NotificationBarProps {
  verifiedEmail: boolean | null;
}

const NotificationBar: React.FC<NotificationBarProps> = ({ verifiedEmail }) => {
  const handleResendVerification = async () => {
    try {
      const response = await fetch("/api/email/resend-verification-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Failed to send verification email."
        );
      }

      toast.success("Verification email sent successfully.");
    } catch (error: any) {
      toast.error(error.message || "Error sending verification email.");
    }
  };

  if (verifiedEmail) {
    return null;
  }

  return (
    <>
      {/* Main notification bar */}
      <Alert
        className="sticky top-0 z-50 flex items-center justify-between bg-red-600 text-white px-4 py-1"
        variant="destructive"
      >
        <div className="flex items-center">
          <AlertTitle className="mr-2 font-bold">Email Not Verified</AlertTitle>
          <AlertDescription>
            Please verify your email to unlock all features.
          </AlertDescription>
        </div>
        <Button
          variant="secondary"
          size="sm"
          onClick={handleResendVerification}
        >
          Resend Verification Email
        </Button>
      </Alert>
    </>
  );
};

export default NotificationBar;
