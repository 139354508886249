import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import Loader from "../components/utils/Loader";

interface UserData {
  userName: string | null;
  userEmail: string | null;
  planName: string | null;
  memberId: string | null;
  verifiedEmail: boolean | null;
  stripeCustomerId: string | null;
  authProvider: string | null;
  trialEndsIn: string | null;
  mfa_status: boolean | null;
}

const UserContext = createContext<{
  userData: UserData | null;
  refetchUserData: () => Promise<void>;
} | null>(null);

export const useUserContext = () => useContext(UserContext);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);

  const fetchUserData = async () => {
    try {
      const response = await fetch("/api/get-user-data", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch user details.");
      }

      const data = await response.json();
      setUserData({
        userName: data.name,
        userEmail: data.email,
        planName: data.planName,
        memberId: data.memberId,
        verifiedEmail: data.verifiedEmail,
        stripeCustomerId: data.stripeCustomerId,
        authProvider: data.authProvider,
        trialEndsIn: data.trialEndsIn,
        mfa_status: data.mfa_status,
      });
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchUserData().finally(() => setLoading(false));
  }, []);

  const refetchUserData = async () => {
    setLoading(true);
    await fetchUserData();
    setLoading(false);
  };

  return (
    <UserContext.Provider value={{ userData, refetchUserData }}>
      {loading ? <Loader /> : children}
    </UserContext.Provider>
  );
};
