import React from "react";

interface GitHubOAuthButtonProps {
  action: "signup" | "signin";
  label: string;
  planId?: string | null;
  mode?: string | null;
  trialDays?: number | null;
}

const GitHubOAuthButton: React.FC<GitHubOAuthButtonProps> = ({
  action,
  label,
  planId,
  mode,
  trialDays,
}) => {
  const handleGitHubOAuth = () => {
    const clientId = process.env.REACT_APP_GITHUB_CLIENT_ID;
    const redirectUri = `${window.location.origin}/auth/github/callback`;
    const scope = "read:user user:email";

    const state = encodeURIComponent(
      JSON.stringify({ action, planId, mode, trialDays })
    );
    const authUrl = `https://github.com/login/oauth/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri
    )}&scope=${encodeURIComponent(scope)}&state=${state}`;

    window.location.href = authUrl;
    console.log("Redirecting to GitHub OAuth URL:", authUrl);
  };

  return (
    <button
      onClick={handleGitHubOAuth}
      className="flex items-center justify-center px-4 py-2 text-white bg-black rounded hover:bg-gray-800"
    >
      <svg
        className="w-5 h-5 mr-2"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M12 2C6.48 2 2 6.48 2 12c0 4.41 2.87 8.15 6.84 9.49.5.09.66-.22.66-.48 0-.23-.01-.85-.01-1.67-2.78.6-3.37-1.19-3.37-1.19-.45-1.16-1.1-1.47-1.1-1.47-.9-.62.07-.61.07-.61 1 .07 1.53 1.02 1.53 1.02.89 1.52 2.33 1.08 2.9.82.09-.65.35-1.08.64-1.33-2.22-.25-4.55-1.11-4.55-4.92 0-1.09.39-1.98 1.02-2.68-.1-.25-.45-1.27.1-2.64 0 0 .83-.27 2.72 1.02a9.39 9.39 0 012.47-.33c.84.01 1.69.11 2.47.33 1.88-1.29 2.72-1.02 2.72-1.02.55 1.37.2 2.39.1 2.64.63.7 1.02 1.59 1.02 2.68 0 3.81-2.34 4.67-4.56 4.92.36.31.68.92.68 1.85 0 1.34-.01 2.42-.01 2.75 0 .26.17.58.67.48A10.003 10.003 0 0022 12c0-5.52-4.48-10-10-10z" />
      </svg>
      {label}
    </button>
  );
};

export default GitHubOAuthButton;
