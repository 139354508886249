import React, { useState } from "react";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  InputOTPSeparator,
} from "../ui/input-otp";
import { Button } from "../ui/button";
import { Alert } from "../ui/alert";

interface MFAVerifyProps {
  tempToken: string;
  email: string;
  onSuccess: () => void;
}

const MFAVerify: React.FC<MFAVerifyProps> = ({
  tempToken,
  email,
  onSuccess,
}) => {
  const [otp, setOtp] = useState<string>("".padStart(6, ""));
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (value: string) => {
    setOtp(value);
  };

  const handleVerify = async () => {
    setError(null);

    if (otp.length !== 6 || !/^\d{6}$/.test(otp)) {
      setError("Please enter a valid 6-digit code.");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch("/api/MFA/mfa-verify", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tempToken, totpCode: otp }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "MFA verification failed.");
      }

      const data = await response.json();

      // Token is already set in the cookie by the API
      onSuccess();
    } catch (err: any) {
      console.error("Error during MFA verification:", err.message);
      setError(err.message || "MFA verification failed.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      <h2 className="text-lg font-semibold">Multi-Factor Authentication</h2>
      <p className="text-sm text-gray-600">
        Please enter the 6-digit code from your authenticator app.
      </p>
      <InputOTP maxLength={6} onChange={handleChange}>
        <InputOTPGroup>
          <InputOTPSlot index={0} autoFocus={true} />
          <InputOTPSlot index={1} />
          <InputOTPSlot index={2} />
        </InputOTPGroup>
        <InputOTPSeparator />
        <InputOTPGroup>
          <InputOTPSlot index={3} />
          <InputOTPSlot index={4} />
          <InputOTPSlot index={5} />
        </InputOTPGroup>
      </InputOTP>
      {error && (
        <Alert>
          <p>{error}</p>
        </Alert>
      )}
      <div className="flex justify-center">
        <Button onClick={handleVerify} disabled={loading}>
          {loading ? "Verifying..." : "Verify"}
        </Button>
      </div>
    </div>
  );
};

export default MFAVerify;
