import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from "../../utils/Loader";

const GitHubCallback: React.FC = () => {
  const navigate = useNavigate();
  const [action, setAction] = useState<string | null>(null);
  const [planId, setPlanId] = useState<string | null>(null);
  const [mode, setMode] = useState<string | null>(null);
  const [trialDays, setTrialDays] = useState<number | null>(null);

  useEffect(() => {
    const handleGitHubCallback = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");
      const stateParam = urlParams.get("state");

      if (!code) {
        console.error("No authorization code found.");
        navigate("/login");
        return;
      }

      if (!stateParam) {
        console.error("No state parameter found in GitHub OAuth response.");
        navigate("/login");
        return;
      }

      try {
        // Decode and parse the state parameter
        const state = JSON.parse(decodeURIComponent(stateParam));
        console.log("Parsed OAuth State:", state);

        const {
          action: actionParam,
          planId: plan,
          mode: modeParam,
          trialDays,
        } = state;

        setAction(actionParam);
        setPlanId(plan || null);
        setMode(modeParam || null);
        setTrialDays(trialDays ? parseInt(trialDays, 10) : null);

        // Determine the appropriate API endpoint
        let endpoint = "";
        if (actionParam === "signin") {
          endpoint = "/api/auth/github-signin";
        } else if (actionParam === "signup") {
          endpoint = "/api/auth/github-signup";
        } else {
          throw new Error("Invalid OAuth action.");
        }

        console.log(`Calling endpoint: ${endpoint} with code: ${code}`);

        // Send the GitHub authorization code to the backend
        const response = await axios.post(
          endpoint,
          { code },
          { withCredentials: true }
        );

        if (response.status !== 200 && response.status !== 201) {
          throw new Error("GitHub authentication failed.");
        }

        const data = response.data;

        // Handle MFA scenario for signin
        if (actionParam === "signin" && data.mfaRequired && data.tempToken) {
          // Store temporary token and email in sessionStorage for MFA verification
          sessionStorage.setItem("mfa_tempToken", data.tempToken);
          sessionStorage.setItem("mfa_email", data.email);

          navigate("/login"); // Redirect to login page to handle MFA
          return;
        }

        // Handle MFA scenario for signup
        if (
          actionParam === "signup" &&
          data.qrCode &&
          data.tempSecret &&
          data.userId &&
          data.email
        ) {
          sessionStorage.setItem("mfa_qrCode", data.qrCode);
          sessionStorage.setItem("mfa_tempSecret", data.tempSecret);
          sessionStorage.setItem("mfa_userId", data.userId);
          sessionStorage.setItem("mfa_email", data.email);

          if (plan) sessionStorage.setItem("planId", plan);
          if (modeParam) sessionStorage.setItem("mode", modeParam);
          if (trialDays)
            sessionStorage.setItem("trialDays", trialDays.toString());

          navigate("/signup?mfa=true");
          return;
        }

        // Handle no-MFA scenario
        const { email } = data;

        if (plan) {
          await handleCheckout(plan, email, modeParam, trialDays);
          return;
        }

        // Redirect to dashboard
        navigate("/dashboard");
      } catch (error: any) {
        console.error("Error during GitHub callback:", error.message || error);
        navigate("/login");
      }
    };

    handleGitHubCallback();
  }, [navigate]);

  const handleCheckout = async (
    priceId: string,
    email: string,
    mode: string | null,
    trialDays: number | null
  ) => {
    try {
      const checkoutResponse = await fetch(
        "/api/stripe/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ priceId, email, mode, trialDays }),
          credentials: "include",
        }
      );

      if (!checkoutResponse.ok) {
        const errorData = await checkoutResponse.json();
        throw new Error(
          errorData.error || "Failed to create checkout session."
        );
      }

      const { url } = await checkoutResponse.json();
      window.location.href = url;
    } catch (error) {
      console.error("Checkout Error:", error);
      navigate("/signup");
    }
  };

  return (
    <>
      <div>{`Processing GitHub ${
        action === "signin" ? "sign-in" : "sign-up"
      }...`}</div>
      <Loader />
    </>
  );
};

export default GitHubCallback;
