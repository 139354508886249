import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Circles } from "react-loader-spinner";

const AfterPurchase = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
      navigate("/dashboard");
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      {loading ? (
        <div className="text-center">
          <Circles height="80" width="80" color="blue" ariaLabel="loading" />
          <p className="text-lg text-gray-700">
            Redirecting you to your dashboard...
          </p>
        </div>
      ) : (
        <p className="text-xl text-gray-600">Redirecting...</p>
      )}
    </div>
  );
};

export default AfterPurchase;
