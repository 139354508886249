import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Alert } from "../components/ui/alert";
import { Input } from "../components/ui/input";
import { Button } from "../components/ui/button";
import { Card, CardContent, CardHeader } from "../components/ui/card";
import pricingPlans, { Plan } from "../data/PricingPlans";
import GoogleSignIn from "../components/auth/GoogleSignIn";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Checkbox } from "../components/ui/checkbox";
import Loader from "../components/utils/Loader";
import { Eye, EyeOff } from "lucide-react";
import GitHubOAuthButton from "../components/auth/github/GitHubOAuthButton";
import { enabledOAuthMethods } from "../config";
import SEOTag from "../components/utils/SEOTag";
import MFASetup from "../components/MFA/MFASetup";

const SignUp: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [planId, setPlanId] = useState<string | null>(null);
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  // MFA related states
  const [showMFASetup, setShowMFASetup] = useState(false);
  const [qrCode, setQrCode] = useState("");
  const [tempSecret, setTempSecret] = useState("");
  const [userId, setUserId] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const plan = urlParams.get("planId");
    setPlanId(plan); // Save the planId if present

    const mfa = urlParams.get("mfa");
    if (mfa === "true") {
      // Retrieve MFA details from sessionStorage
      const storedQRCode = sessionStorage.getItem("mfa_qrCode");
      const storedTempSecret = sessionStorage.getItem("mfa_tempSecret");
      const storedUserId = sessionStorage.getItem("mfa_userId");
      const storedEmail = sessionStorage.getItem("mfa_email");
      const storedPlanId = sessionStorage.getItem("planId");

      if (storedQRCode && storedTempSecret && storedUserId && storedEmail) {
        setQrCode(storedQRCode);
        setTempSecret(storedTempSecret);
        setUserId(storedUserId);
        setUserEmail(storedEmail);
        setShowMFASetup(true);

        if (storedPlanId) {
          setPlanId(storedPlanId);
        }

        // Clear the stored MFA details after retrieval
        sessionStorage.removeItem("mfa_qrCode");
        sessionStorage.removeItem("mfa_tempSecret");
        sessionStorage.removeItem("mfa_userId");
        sessionStorage.removeItem("mfa_email");
        sessionStorage.removeItem("planId");
      } else {
        // If MFA details are missing, navigate back to sign-up
        setError("Missing MFA setup details.");
        navigate("/signup");
      }
    } else {
      // If not in MFA flow, set planId from URL if present
      if (plan) {
        setPlanId(plan);
      }
    }
  }, [navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTermsChange = (checked: boolean) => {
    setAcceptedTerms(checked);
  };

  const getPlanDetails = (planId: string): Plan | null => {
    const allPlans = Object.values(pricingPlans).flat();
    return allPlans.find((plan) => plan.id === planId) || null;
  };

  const handleCheckout = async (priceId: string, email: string) => {
    try {
      setLoading(true);
      const plan = getPlanDetails(priceId);

      if (!plan) {
        throw new Error("Invalid plan ID provided for checkout.");
      }

      const { trialDays, type } = plan;
      const mode = type === "subscription" ? "subscription" : "payment";

      const checkoutResponse = await fetch(
        "/api/stripe/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ priceId, email, trialDays, mode }),
        }
      );

      if (!checkoutResponse.ok) {
        const errorData = await checkoutResponse.json();
        console.error("Checkout session error:", errorData);
        throw new Error("Failed to create checkout session.");
      }

      const checkoutData = await checkoutResponse.json();
      window.location.href = checkoutData.url; // Redirect to Stripe Checkout
    } catch (error: any) {
      console.error("Error during checkout session creation:", error);
      setError("Error creating checkout session.");
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!acceptedTerms) {
      setError("You must accept the terms and conditions to proceed.");
      return;
    }

    try {
      setLoading(true);
      const response = await fetch("/api/auth/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Sign-up failed.");
      }

      const data = await response.json();
      console.log("GitHub Callback Response:", data); // For debugging

      // Check if MFA is required or optional
      if (data.qrCode && data.tempSecret) {
        // MFA scenario
        setQrCode(data.qrCode);
        setTempSecret(data.tempSecret);
        setUserId(data.userId);
        setUserEmail(data.email);
        setShowMFASetup(true);
      } else {
        // No MFA scenario
        const { token } = data;
        if (token) {
          localStorage.setItem("authToken", token);

          // If planId is present, go to checkout directly
          if (planId) {
            await handleCheckout(planId, formData.email);
            return;
          }

          // Otherwise go to dashboard
          navigate("/dashboard");
        } else {
          // If no token is returned (e.g., user must verify email)
          navigate("/pricing");
        }
      }
    } catch (err: any) {
      console.error("Error during sign-up:", err.message);
      setError(err.message || "An unknown error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSuccess = async (credentialResponse: any) => {
    try {
      setLoading(true);
      const { credential } = credentialResponse;

      if (!credential) {
        throw new Error("Missing credential in Google response.");
      }

      const googleAuthResponse = await fetch("/api/auth/google-signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ credential }),
      });

      if (!googleAuthResponse.ok) {
        const errorData = await googleAuthResponse.json();
        throw new Error(errorData.error || "Google sign-up failed.");
      }

      const data = await googleAuthResponse.json();
      console.log("Google Callback Response:", data); // For debugging

      // Check if MFA is required or optional
      if (data.qrCode && data.tempSecret && data.userId && data.email) {
        // MFA scenario
        setQrCode(data.qrCode);
        setTempSecret(data.tempSecret);
        setUserId(data.userId);
        setUserEmail(data.email);
        setShowMFASetup(true);
      } else {
        // No MFA scenario
        const { token } = data;
        if (token) {
          localStorage.setItem("authToken", token);

          if (planId) {
            await handleCheckout(planId, data.email);
            return;
          }

          navigate("/dashboard");
        } else {
          navigate("/pricing");
        }
      }
    } catch (err: any) {
      console.error("Google Sign-In Error:", err.message);
      setError(err.message || "Google sign-up failed.");
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleFailure = () => {
    setError("Google sign-up failed. Please try again.");
  };

  // After MFA success or skip
  const handleMFASuccess = async () => {
    // Once MFA is verified or skipped, user is authenticated
    if (planId) {
      await handleCheckout(planId, userEmail);
    } else {
      window.location.href = "/dashboard";
    }
  };

  if (loading) {
    return <Loader />;
  }

  const hasOAuthMethods = enabledOAuthMethods.some(
    (method) => method !== "email"
  );

  return (
    <>
      <SEOTag
        title="Signup - My SaaS App"
        description="Create an account for free"
        url="https://www.create-saas-app.com"
        image="https://mywebsite.com/about-og-image.jpg"
      />

      <GoogleOAuthProvider
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
      >
        <div className="flex justify-center items-center min-h-screen bg-gray-50">
          <Card className="max-w-md w-full shadow-md">
            <CardHeader>
              <h1 className="text-xl font-bold text-center">Sign Up</h1>
            </CardHeader>
            <CardContent>
              {error && (
                <Alert>
                  <p>{error}</p>
                </Alert>
              )}

              {/* If MFA setup is required, show MFASetup component */}
              {showMFASetup ? (
                <MFASetup
                  userId={userId}
                  email={userEmail}
                  qrCode={qrCode}
                  tempSecret={tempSecret}
                  onSuccess={handleMFASuccess}
                />
              ) : (
                <>
                  {enabledOAuthMethods.includes("email") && (
                    <form onSubmit={handleSubmit} className="space-y-4">
                      <div>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div>
                        <Input
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="relative">
                        <Input
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          value={formData.password}
                          onChange={handleChange}
                          required
                        />
                        <button
                          type="button"
                          className="absolute inset-y-0 right-3 flex items-center text-gray-500"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? (
                            <EyeOff className="w-5 h-5" />
                          ) : (
                            <Eye className="w-5 h-5" />
                          )}
                        </button>
                      </div>
                      <div className="flex items-center space-x-2">
                        <Checkbox
                          id="terms"
                          checked={acceptedTerms}
                          onCheckedChange={handleTermsChange}
                        />
                        <label
                          htmlFor="terms"
                          className="text-sm text-gray-600"
                        >
                          I agree to the{" "}
                          <a
                            href="/terms"
                            target="_blank"
                            className="text-blue-500"
                          >
                            Terms and Conditions
                          </a>
                        </label>
                      </div>
                      <Button type="submit">Sign Up</Button>
                    </form>
                  )}

                  {hasOAuthMethods && (
                    <div className="mt-4">
                      <p className="text-center">Or sign up with:</p>
                      <div className="flex flex-col justify-center items-center mt-2 h-full space-y-4">
                        {enabledOAuthMethods.includes("google") && (
                          <GoogleSignIn
                            onSuccess={handleGoogleSuccess}
                            onFailure={handleGoogleFailure}
                          />
                        )}
                        {enabledOAuthMethods.includes("github") && (
                          <GitHubOAuthButton
                            action="signup"
                            label="Sign up with GitHub"
                            planId={planId}
                            mode={
                              planId ? getPlanDetails(planId)?.type : undefined
                            }
                            trialDays={
                              planId
                                ? getPlanDetails(planId)?.trialDays
                                : undefined
                            }
                          ></GitHubOAuthButton>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="mt-4 text-center">
                    <p>
                      Already have an account?{" "}
                      <Button
                        variant="link"
                        onClick={() => navigate("/login")}
                        className="p-0"
                      >
                        Log In
                      </Button>
                    </p>
                  </div>
                </>
              )}
            </CardContent>
          </Card>
        </div>
      </GoogleOAuthProvider>
    </>
  );
};

export default SignUp;
