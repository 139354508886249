import React from "react";
import { Outlet } from "react-router-dom";
import DashboardLayout from "../Layouts/DashboardLayout";
import { useUserContext } from "../context/UserContext";
import Loader from "../components/utils/Loader";

const DashboardPage: React.FC = () => {
  const userContext = useUserContext();

  if (!userContext) {
    return <Loader />;
  }

  const { userData, refetchUserData } = userContext;

  return (
    <DashboardLayout verifiedEmail={userData?.verifiedEmail ?? null}>
      <div className="p-4">
        <Outlet context={{ refetchUserData }} />
      </div>
    </DashboardLayout>
  );
};

export default DashboardPage;
