import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const VerifyEmailPage: React.FC = () => {
  const [message, setMessage] = useState<string | null>("Verifying email...");
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get("token");

      if (!token) {
        setMessage("Invalid verification link.");
        return;
      }

      try {
        const response = await fetch("/api/email/verify-email", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        });

        if (!response.ok) {
          throw new Error("Verification failed.");
        }

        setMessage("Email verified successfully! Redirecting...");
        setTimeout(() => navigate("/dashboard"), 3000);
      } catch (error) {
        setMessage("Verification failed. Please try again.");
      }
    };

    verifyEmail();
  }, [navigate]);

  return (
    <div className="flex justify-center items-center min-h-screen">
      <div className="p-6 bg-white shadow rounded">
        <h1 className="text-2xl font-bold text-center">Email Verification</h1>
        <p className="text-center mt-4">{message}</p>
      </div>
    </div>
  );
};

export default VerifyEmailPage;
